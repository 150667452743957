import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  useLocation,
  useSearchParams,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import AccountTab from "./AccountTab";
import PermissionTab from "./PermissionTab";
import { useForm } from "react-hook-form";
import {
  schema,
  validation,
} from "../../../components/Form/UserAccount/schema";
import {
  postNewUser,
  getUser,
  updateUser,
  deleteUser,
} from "../../../features/User/Account/account-actions";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  departmentThaiToEnum,
  formatUserAccount,
} from "../../../utils/dataTransformer";
import { uploadFileToS3 } from "../../../utils/s3";
import { getAllRolesNoAggrid } from "../../../features/User/Rbac/rbac-action";
import CustomizedMenuOption from "../../../components/Custom/CustomizedMenuOption";
import { Box } from "@mui/material";
import ModalUI from "../../../components/UI/ModalUI";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useAuth } from "../../../hooks/use-auth";

const AccountContainer = ({ viewOnly, addable, setting }) => {
  // const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, permissions } = useAuth();
  const { user: employee } = useSelector((state) => state.account);
  const { allRoles } = useSelector((state) => state.rbac);
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const tab = searchParams.get("tab");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [option, setOption] = useState("");
  const [currentRoleError, setCurrentRoleError] = useState(undefined);

  const userPermission = permissions.user;

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    getFieldState,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { ...schema },
    resolver: yupResolver(validation),
    // mode: "onSubmit",
    // reValidateMode: "onSubmit",
  });

  // const admin = user?.role_list?.some((role) =>
  //   role?.permission_list?.includes("ADMIN__ADMIN__ADMIN")
  // );

  useEffect(() => {
    dispatch(getAllRolesNoAggrid({}, enqueueSnackbar));
  }, []);

  useEffect(() => {
    setIsInit(false);
  }, [employeeId]);

  useEffect(() => {
    //get data to be rendered as default values
    if (!isInit) {
      const getEmployee = async () => {
        if (employeeId || setting) {
          const getUserPayload = {
            unique_id: setting ? user.unique_id : employeeId,
          };

          await dispatch(getUser(getUserPayload, enqueueSnackbar));
          if (employee) {
            const formattedEmployee = formatUserAccount(employee);
            reset({
              ...formattedEmployee,
              change_password: false,
            });
            setOption("");
          }
        } else {
          await dispatch(getUser("init", enqueueSnackbar));
        }
        setIsInit(true);
      };
      getEmployee();
    }
  }, [dispatch, employeeId, enqueueSnackbar, setting, user, employee, isInit]);

  // useEffect(() => {
  //   //replace default values of form hook with new value from redux
  //   if (employee) {
  //     const formattedEmployee = formatUserAccount(employee);
  //     reset({
  //       ...formattedEmployee,
  //     });
  //   }
  // }, [employee, setValue]);

  const onResetForm = () => {
    if (employee) {
      const formattedEmployee = formatUserAccount(employee);
      reset({
        ...formattedEmployee,
        change_password: employeeId ? false : true,
      });
      setOption("");
    } else {
      reset({ ...schema });
    }
  };

  const onUserSubmit = async (data, type) => {
    if (type === "triggerRevalidate") {
      return;
    }

    let img_url = "";
    if (data.img_url && typeof data.img_url[0] === "object") {
      const { Location } = await uploadFileToS3(
        data.img_url[0],
        "user",
        setting ? user.unique_id : ""
      );
      img_url = Location;
    }
    const selectedRole = allRoles.find(
      (role) => role.name === data.current_role
    );
    const newUserPayload = {
      title_name: data.title_name,
      first_name: data.first_name,
      last_name: data.last_name,
      nick_name: data.nick_name,
      email: data.email.toLowerCase(),
      img_url:
        data.img_url && data.img_url.length > 0
          ? typeof data.img_url[0] === "object"
            ? img_url
            : data.img_url[0]
          : null,
      phone: data.phone.toString(),
      position: data.position,
      department: departmentThaiToEnum(data.department),
      organization: data.organization,
      inactive_remarks: data.inactive_remarks,
      user_credential: data.change_password
        ? {
            password: data.password,
          }
        : undefined,
      is_user_active:
        typeof data.is_user_active === "boolean"
          ? data.is_user_active
          : data.is_user_active === "true" || data.is_user_active !== "false"
          ? true
          : false,
      role_unique_id_list: [selectedRole.unique_id],
    };
    if (viewOnly && employeeId) {
      dispatch(
        updateUser(
          { unique_id: setting ? user.unique_id : employeeId },
          newUserPayload,
          setting,
          enqueueSnackbar,
          setOption
        )
      );
    } else {
      dispatch(postNewUser("user", newUserPayload, navigate, enqueueSnackbar));
    }
  };

  const onError = () => {
    const firstNameError = getFieldState("first_name").error;
    const lastNameError = getFieldState("last_name").error;
    const emailError = getFieldState("email").error;
    const passwordError = getFieldState("password").error;
    const confirmPasswordError = getFieldState("confirm_password").error;
    const errorsCount =
      (firstNameError ? 1 : 0) +
      (lastNameError ? 1 : 0) +
      (emailError ? 1 : 0) +
      (passwordError ? 1 : 0) +
      (confirmPasswordError ? 1 : 0) +
      (currentRoleError ? 1 : 0);

    // if (errorsCount > 1) {
    //   enqueueSnackbar("กรุณาระบุข้อมูล", {
    //     variant: "error",
    //   });
    // }

    if (errorsCount === 1 && Boolean(firstNameError)) {
      enqueueSnackbar(firstNameError.message, {
        variant: "error",
      });
    }

    if (errorsCount === 1 && Boolean(lastNameError)) {
      enqueueSnackbar(lastNameError.message, {
        variant: "error",
      });
    }

    if (errorsCount === 1 && Boolean(emailError)) {
      enqueueSnackbar(emailError.message, {
        variant: "error",
      });
    }

    if (errorsCount === 1 && Boolean(passwordError)) {
      enqueueSnackbar(passwordError.message, {
        variant: "error",
      });
    }

    if (errorsCount === 1 && Boolean(confirmPasswordError)) {
      enqueueSnackbar(confirmPasswordError.message, {
        variant: "error",
      });
    }

    if (errorsCount === 1 && Boolean(currentRoleError)) {
      enqueueSnackbar(currentRoleError.message, {
        variant: "error",
      });
    }
  };

  const onDeleteUser = () => {
    try {
      if (employee.unique_id === user.unique_id) {
        setModalIsOpen(false);
        throw new Error("ไม่สามารถลบผู้ใช้งานได้");
      }
      dispatch(deleteUser({ unique_id: employee.unique_id }));
      enqueueSnackbar("ลบผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
      navigate("/user/account");
    } catch (err) {
      enqueueSnackbar("ไม่สามารถลบผู้ใช้งานได้", {
        variant: "error",
      });
    }
  };

  // const onDisableUser = () => {
  //   try {
  //     dispatch(disableUser({ unique_id: employee.unique_id }));
  //     enqueueSnackbar("ลบผู้ใช้งานสำเร็จ", {
  //       variant: "success",
  //     });
  //     navigate("/user/account");
  //   } catch (err) {
  //     enqueueSnackbar("ไม่สามารถลบผู้ใช้งานได้", {
  //       variant: "error",
  //     });
  //   }
  // };

  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.account.index"),
      to: "/user/account",
    },
    {
      name: employeeId ? employeeId : t("user.account.addNewAccount"),
    },
    // {
    //   name: viewOnly
    //     ? `${employee.first_name} ${employee.last_name}`
    //     : t("user.account.addNewAccount"),
    // },
  ];
  setting && breadcrumbs.splice(2, 1);

  const tabs = [
    {
      label: t("user.account.info"),
      path: `${pathname}`,
    },
    {
      label: t("user.account.permission"),
      path: `${pathname}?tab=permission`,
    },
  ];

  const currentTab = tab ? pathname + `?tab=${tab}` : pathname;

  const options = user.unique_id === employeeId ? ["แก้ไข"] : ["แก้ไข", "ลบ"];

  return (
    <>
      {userPermission === "NONE" && user.unique_id !== employeeId && (
        <Navigate to="/no-permission" />
      )}
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider />
      {((viewOnly && userPermission === "EDITABLE") ||
        user.unique_id === employeeId) && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 4 }}>
          <CustomizedMenuOption
            label={"ตัวเลือก"}
            options={options}
            onSelect={(e) => {
              if (e.target.innerText === "ลบ") {
                setModalIsOpen(true);
              } else {
                setOption(e.target.innerText);
              }
            }}
          />
        </Box>
      )}
      <form>
        {!tab ? (
          <AccountTab
            control={control}
            errors={errors}
            setValue={setValue}
            trigger={trigger}
            viewOnly={viewOnly}
            // admin={admin}
            // TODO remove this
            addable={addable}
            getValues={getValues}
            onTriggerRevalidate={handleSubmit((data) =>
              onUserSubmit(data, "triggerRevalidate")
            )}
            onSubmit={handleSubmit((data) => onUserSubmit(data), onError())}
            setting={setting}
            onReset={onResetForm}
            option={option}
          />
        ) : (
          <PermissionTab
            control={control}
            errors={errors}
            setValue={setValue}
            viewOnly={viewOnly}
            currentRoleError={currentRoleError}
            setCurrentRoleError={setCurrentRoleError}
            onTriggerRevalidate={handleSubmit((data) =>
              onUserSubmit(data, "triggerRevalidate")
            )}
            onSubmit={handleSubmit((data) => onUserSubmit(data), onError())}
            onReset={onResetForm}
            option={option}
          />
        )}
      </form>
      <ModalUI
        open={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        maxWidth="xs"
        title="ยืนยันการลบผู้ใช้งาน"
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <CustomizedButton
            sx={{ ml: 1 }}
            title={"ยกเลิก"}
            variant="contained"
            onClick={() => setModalIsOpen(false)}
          />
          <CustomizedButton
            sx={{ ml: 1 }}
            title={"ยืนยัน"}
            variant="contained"
            onClick={onDeleteUser}
          />
        </Box>
      </ModalUI>
    </>
  );
};

export default AccountContainer;
