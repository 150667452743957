import UserService from "../../../services/User";
import GlobalService from "../../../services/Global";
import { accountActions, initialState } from "./account-slice";

export const getAllUsers =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(accountActions.onLoading("allUsers"));
    try {
      const allUsers = await UserService.getAllUsers(input);
      params.successCallback(allUsers.results, allUsers.count);
      dispatch(accountActions.loadedAllUsers(allUsers.results));
    } catch (err) {
      dispatch(accountActions.rejectedActions({ ...err, name: "allUsers" }));
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllUsersNoAggrid = () => async (dispatch) => {
  dispatch(accountActions.onLoading("allUsers"));
  try {
    const allUsers = await UserService.getAllUsersNoAggrid();
    dispatch(accountActions.loadedAllUsers(allUsers));
  } catch (err) {
    dispatch(accountActions.rejectedActions({ ...err, name: "allUsers" }));
  }
};

export const postNewUser =
  (modelType, createUserInput, navigate, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(accountActions.onLoading("user"));
    try {
      const unique_id = await GlobalService.getUniqueId(modelType);
      const payload = {
        unique_id,
        ...createUserInput,
      };
      const newUser = await UserService.createUser(payload);
      dispatch(accountActions.appendedNewUser(newUser));
      enqueueSnackbar("เพิ่มผู้ใช้งานสำเร็จ", {
        variant: "success",
      });
      navigate(`/user/account/${unique_id}`);
    } catch (err) {
      dispatch(accountActions.rejectedActions({ ...err, name: "user" }));
      enqueueSnackbar("เพิ่มผู้ใช้งานไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const getUser = (uniqueInput, enqueueSnackbar) => async (dispatch) => {
  dispatch(accountActions.onLoading("user"));
  try {
    if (uniqueInput === "init") {
      dispatch(accountActions.loadedUser(initialState.user));
    } else {
      const user = await UserService.getUser(uniqueInput);
      dispatch(accountActions.loadedUser(user));
    }
  } catch (err) {
    dispatch(accountActions.rejectedActions({ ...err, name: "user" }));
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const updateUser =
  (uniqueInput, updateInput, isSetting, enqueueSnackbar, setOption) =>
  async (dispatch) => {
    dispatch(accountActions.onLoading("user"));
    dispatch(accountActions.onLoading("allUsers"));
    try {
      const updateduser = await UserService.updateUser(
        uniqueInput,
        updateInput
      );
      dispatch(accountActions.updatedUser(updateduser));
      if (isSetting) {
        enqueueSnackbar("แก้ไขข้อมูลสำเร็จ", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("แก้ไขผู้ใช้งานสำเร็จ", {
          variant: "success",
        });
      }
      setOption("");
    } catch (err) {
      dispatch(accountActions.rejectedActions({ ...err, name: "allUsers" }));
      if (isSetting) {
        enqueueSnackbar("แก้ไขข้อมูลไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("แก้ไขผู้ใช้งานไม่สำเร็จ", {
          variant: "error",
        });
      }
      console.log(err);
    }
  };

export const disableUser = (uniqueInput) => async (dispatch) => {
  dispatch(accountActions.onLoading("allUsers"));
  try {
    const disabledUser = await UserService.disableUser(uniqueInput);
    dispatch(accountActions.disabledUser(disabledUser));
  } catch (err) {
    dispatch(accountActions.rejectedActions({ ...err, name: "allUsers" }));
  }
};

export const deleteUser = (uniqueInput) => async (dispatch) => {
  dispatch(accountActions.onLoading("allUsers"));
  try {
    const disabledUser = await UserService.deleteUser(uniqueInput);
    dispatch(accountActions.disabledUser(disabledUser));
  } catch (err) {
    dispatch(accountActions.rejectedActions({ ...err, name: "allUsers" }));
  }
};
