import { Box, LinearProgress } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useParams,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ManufacturerForm from "../../../components/Form/ManufactureOrder/ManufacturerForm";
import CustomerForm from "../../../components/Form/ManufactureOrder/CustomerForm";
import { useAuth } from "../../../hooks/use-auth";
import Header from "./Header";
import MOItemTable from "../../../components/Table/MOItemTable";
import SummaryForm from "../../../components/Form/ManufactureOrder/SummaryForm";
import CancelDetailForm from "../../../components/Form/ManufactureOrder/CancelDetailForm";
import {
  createManufactureOrder,
  updateManufactureOrder,
  getManufactureOrder,
  cancelManufactureOrder,
} from "../../../features/Manufacture/ManufactureOrder/manufacture-order-actions";
import { manufactureOrderValidation } from "../../../features/Manufacture/ManufactureOrder/manufacture-order-initial";
import InventoryService from "../../../services/Inventory";
import GlobalService from "../../../services/Global";
import { formatManufactureOrderQuery } from "../../../utils/manufacturePayloadFormatter";
import { useSnackbar } from "notistack";
import StatusChangeConfirmation from "../../../components/UI/Confirmation/StatusChangeConfirmation";

const ManufactureOrderContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { user, permissions } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { manufactureOrder } = useSelector((state) => state.manufactureOrder);
  const { enqueueSnackbar } = useSnackbar();
  const [isEdit, setIsEdit] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [existingItemIds, setExistingItemIds] = useState(null);
  const [statusChangeConfirmation, setStatusChangeConfirmation] =
    useState(false);
  const vendorFormRef = useRef();
  const customerFormRef = useRef();
  const cancelDetailFormRef = useRef();

  const manufacturePermission = permissions.manufacture;
  const isViewOnly = manufacturePermission === "VIEW__ONLY";

  const breadcrumbs = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.order.index"),
      to: "/manufacture/order",
    },
    {
      name: id ?? `สร้าง${t("manufacture.order.index")}`,
    },
  ];

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm({
    defaultValues: id
      ? manufactureOrder
      : {
          ...manufactureOrder,
          created_date: new Date(),
          payment_date: null,
          production_date: new Date(),
          receive_date: new Date(),
          creator_unique_id: user?.unique_id,
          created_by: {
            unique_id: user?.unique_id,
            first_name: user?.first_name,
            last_name: user?.last_name,
            img_url: user?.img_url,
          },
        },
    resolver: yupResolver(manufactureOrderValidation),
  });

  //TODO move useWatch to child state
  //
  const watchUniqueId = useWatch({ control, name: "unique_id" });
  const watchStatus = useWatch({ control, name: "status" });
  const watchPendingCancel = useWatch({ control, name: "pending_cancel" });

  useEffect(() => {
    if (!isInit) {
      const loadedManufactureOrder = async () => {
        if (state) {
          await dispatch(getManufactureOrder("init", enqueueSnackbar));
          const formattedManufactureOrder = formatManufactureOrderQuery(
            manufactureOrder,
            state
          );
          reset({
            ...formattedManufactureOrder,
            unique_id: null,
            render_status: null,
            main_status: null,
            sub_status: null,
            flag_status: null,
            status: null,
            creator_unique_id: user?.unique_id,
            created_by: {
              unique_id: user?.unique_id,
              first_name: user?.first_name,
              last_name: user?.last_name,
              img_url: user?.img_url,
            },
          });
        } else if (id) {
          const loadManufactureOrderPayload = { unique_id: id };
          const loadedManufactureOrder = await dispatch(
            getManufactureOrder(
              loadManufactureOrderPayload,
              enqueueSnackbar,
              navigate
            )
          );
          if (manufactureOrder.unique_id) {
            const formattedManufactureOrder = formatManufactureOrderQuery(
              loadedManufactureOrder
            );
            reset({
              ...formattedManufactureOrder,
            });
          }
        } else {
          await dispatch(getManufactureOrder("init", enqueueSnackbar));
          reset({
            ...manufactureOrder,
            creator_unique_id: user?.unique_id,
            created_by: {
              unique_id: user?.unique_id,
              first_name: user?.first_name,
              last_name: user?.last_name,
              img_url: user?.img_url,
            },
          });
        }
        setIsInit(true);
      };
      loadedManufactureOrder();
    }
  }, [dispatch, id, enqueueSnackbar, manufactureOrder, isInit]);

  useEffect(() => {
    if (!watchUniqueId && !id) {
      const getManufactureOrderUniqueId = async () => {
        try {
          const manufactureOrderId = await GlobalService.getUniqueId(
            "manufacture_order"
          );
          setValue("unique_id", manufactureOrderId);
        } catch (err) {
          console.log(err);
          console.log("Could not generate item ID");
        }
      };
      getManufactureOrderUniqueId();
    }
  }, [watchUniqueId]);

  useEffect(() => {
    if (!existingItemIds) {
      const getItemIds = async () => {
        const itemIds = await InventoryService.getItemIds();
        setExistingItemIds(itemIds);
      };
      getItemIds();
    }
  }, [existingItemIds]);

  useEffect(() => {
    if (isViewOnly) {
      setDisabled(true);
      return;
    }
    switch (watchStatus) {
      case "wait_manufacture":
      case "manufacturing":
      case "finished":
      case "cancelled":
        setDisabled(true);
        break;
      default:
        setDisabled(false);
    }
  }, [watchStatus]);

  const onError = (data) => {
    const vendorIsInvalid = data.vendor_unique_id;
    const customerIsInvalid = data.customer_unique_id;
    const deliveryAddressIsInvalid = data.delivery_address;
    const itemListAmountIsInvalid = data.item_list;

    let scrollable = true;

    if (vendorIsInvalid) {
      enqueueSnackbar(vendorIsInvalid.message, {
        variant: "error",
      });
      if (scrollable) {
        vendorFormRef.current.scrollIntoView({ behavior: "smooth" });
        scrollable = false;
      }
    }

    if (customerIsInvalid) {
      enqueueSnackbar(customerIsInvalid.message, {
        variant: "error",
      });
      if (scrollable) {
        customerFormRef.current.scrollIntoView({ behavior: "smooth" });
        scrollable = false;
      }
    }

    if (deliveryAddressIsInvalid) {
      enqueueSnackbar(deliveryAddressIsInvalid.message, {
        variant: "error",
      });
      if (scrollable) {
        customerFormRef.current.scrollIntoView({ behavior: "smooth" });
        scrollable = false;
      }
    }

    if (itemListAmountIsInvalid) {
      enqueueSnackbar("กรุณาระบุจำนวนสินค้าแต่ละรายการอย่างน้อย 1 ชิ้น", {
        variant: "error",
      });
    }
  };

  const itemListIsValid = async (data) => {
    let itemListIsValid = true;

    if (data.item_list.length === 0) {
      itemListIsValid = false;
      enqueueSnackbar("กรุณาเลือกสินค้าอย่างน้อย 1 รายการ", {
        variant: "error",
      });
    }

    const itemIds = await InventoryService.getItemIds();
    setExistingItemIds(itemIds);

    data.item_list.forEach((item) => {
      if (!itemIds.includes(item.item_unique_id)) {
        if (itemListIsValid) {
          itemListIsValid = false;
          enqueueSnackbar("กรุณาลบสินค้า", { variant: "error" });
        }
      }
    });
    return itemListIsValid;
  };

  const saveDraftHandler = async (data) => {
    const itemListValid = await itemListIsValid(data);
    if (!itemListValid) return;
    if (!id) {
      dispatch(
        createManufactureOrder(data, "draft", navigate, enqueueSnackbar)
      );
    } else {
      dispatch(
        updateManufactureOrder(data, "draft", enqueueSnackbar, setIsInit)
      );
    }
  };

  const submitEditHandler = async (data) => {
    const itemListValid = await itemListIsValid(data);
    if (!itemListValid) return;
    const pendingCancel = getValues("pending_cancel");
    if (pendingCancel) {
      setStatusChangeConfirmation(true);
    } else {
      dispatch(
        updateManufactureOrder(data, "submit", enqueueSnackbar, setIsInit)
      );
    }
    setDisabled(true);
  };

  const sendToManufacture = async (data) => {
    const itemListValid = await itemListIsValid(data);
    if (!itemListValid) return;
    if (!id) {
      dispatch(
        createManufactureOrder(
          data,
          "sendToManufacture",
          navigate,
          enqueueSnackbar
        )
      );
    } else {
      dispatch(
        updateManufactureOrder(
          data,
          "sendToManufacture",
          enqueueSnackbar,
          setIsInit
        )
      );
    }
    setDisabled(true);
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    reset(manufactureOrder);
    setDisabled(true);
  };

  const scrollToCancelDetailForm = () => {
    setTimeout(() =>
      cancelDetailFormRef.current.scrollIntoView({ behavior: "smooth" })
    ),
      100;
    trigger();
  };

  const statusOptionSelectHandler = () => {
    const data = getValues();
    dispatch(cancelManufactureOrder(data, enqueueSnackbar, setIsInit));
    setStatusChangeConfirmation(false);
  };

  const closeStatusChangeConfirmationHandler = () => {
    setStatusChangeConfirmation(false);
  };

  return (
    <>
      {manufacturePermission === "NONE" && <Navigate to="/no-permission" />}
      {!isInit && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {isInit && (
        <form>
          <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
          <Header
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            disabled={disabled}
            editClickHandler={editClickHandler}
            scrollToCancelDetailForm={scrollToCancelDetailForm}
            setIsInit={setIsInit}
          />
          <ManufacturerForm
            control={control}
            errors={errors}
            disabled={disabled}
            setValue={setValue}
            getValues={getValues}
            id={id}
            ref={vendorFormRef}
          />
          <CustomerForm
            control={control}
            errors={errors}
            disabled={disabled}
            setValue={setValue}
            getValues={getValues}
            state={state}
            id={id}
            ref={customerFormRef}
          />
          <MOItemTable
            control={control}
            errors={errors}
            disabled={disabled}
            setValue={setValue}
            existingItemIds={existingItemIds}
          />
          <SummaryForm control={control} errors={errors} disabled={disabled} />
          <CancelDetailForm
            control={control}
            errors={errors}
            disabled={disabled}
            setValue={setValue}
            ref={cancelDetailFormRef}
          />
          {!disabled && !isEdit && (
            <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
              <CustomizedButton
                title="บันทึกร่าง"
                variant="outlined"
                onClick={handleSubmit(saveDraftHandler, onError)}
              />
              <CustomizedButton
                title="ยืนยัน"
                variant="contained"
                onClick={handleSubmit(sendToManufacture, onError)}
              />
            </Box>
          )}
          {(watchPendingCancel || (!disabled && isEdit)) && (
            <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
              <CustomizedButton
                title="ยกเลิก"
                variant="outlined"
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title="บันทึก"
                variant="contained"
                onClick={handleSubmit(submitEditHandler, onError)}
              />
            </Box>
          )}
          <StatusChangeConfirmation
            currentStatus={watchStatus}
            targetStatus={"ยกเลิก"}
            openStatusChangeConfirmation={statusChangeConfirmation}
            statusChangeConfirmationAction={statusOptionSelectHandler}
            closeStatusChangeConfirmationHandler={
              closeStatusChangeConfirmationHandler
            }
            translate={t}
          />
        </form>
      )}
    </>
  );
};

export default ManufactureOrderContainer;
