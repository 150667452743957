import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import AgGrid from "../../../components/Table/AgGrid";
import { manufactureOrderColumnDef } from "../../../components/Table/ColumnDefs/Manufacture/Order";
import { manufactureOrderActions } from "../../../features/Manufacture/ManufactureOrder/manufacture-order-slice";
import { getAllManufactureOrders } from "../../../features/Manufacture/ManufactureOrder/manufacture-order-actions";
import { useAuth } from "../../../hooks/use-auth";
import { dateFilterModel } from "../../../utils/dataTransformer";
import ManufactureService from "../../../services/Manufacture";

const ManufactureOrder = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const navigate = useNavigate();
  const [tagOptions, setTagOptions] = useState([]);
  const [isInit, setIsInit] = useState(false);
  const { permissions } = useAuth();
  const manufacturePermission = permissions.manufacture;

  useEffect(() => {
    if (!isInit) {
      const getTags = async () => {
        const loadedManufactureOrder =
          await ManufactureService.getManufactureOrderTags();
        const formattedTags = loadedManufactureOrder
          .map((manufactureOrder) => manufactureOrder.tag_list)
          .flat();
        const uniqueTags = [...new Set(formattedTags)];
        setTagOptions([...uniqueTags, ""]);
      };
      getTags();
      setIsInit(true);
    }
  }, [isInit]);

  const breadcrumbs = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.order.index"),
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const tabs = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_manufacture"),
      path: `${pathname}?filter=wait_manufacture`,
    },
    {
      label: t("status.manufacturing"),
      path: `${pathname}?filter=manufacturing`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      const filterModel = {
        ...request.filterModel,
        unique_id: {
          ...request.filterModel.unique_id,
          mode: "insensitive",
        },
        external_ref_document_id: {
          ...request.filterModel.external_ref_document_id,
          mode: "insensitive",
        },
        created_date: dateFilterModel(request.filterModel.created_date),
        payment_date: dateFilterModel(request.filterModel.payment_date),
        production_date: dateFilterModel(request.filterModel.production_date),
        receive_date: dateFilterModel(request.filterModel.receive_date),
        vendor_name: {
          ...request.filterModel.vendor_name,
          mode: "insensitive",
        },
        customer_name: {
          ...request.filterModel.customer_name,
          mode: "insensitive",
        },
        tag_list: request.filterModel.tag_list
          ? {
              filterType: "array",
              type: "hasSome",
              values:
                request.filterModel.tag_list?.values?.filter((tag) => tag)
                  .length > 0
                  ? request.filterModel.tag_list?.values
                  : [],
            }
          : undefined,
      };
      dispatch(
        getAllManufactureOrders(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: filterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      const instance = params.api.getFilterInstance("render_status");
      switch (isFilter) {
        case "draft":
          instance.setModel({ values: ["draft"] });
          break;
        case "wait_manufacture":
          instance.setModel({ values: ["wait_manufacture"] });
          break;
        case "manufacturing":
          instance.setModel({ values: ["manufacturing"] });
          break;
        case "finished":
          instance.setModel({ values: ["finished"] });
          break;
        case "cancelled":
          instance.setModel({ values: ["cancelled"] });
          break;
        default:
          instance.setModel({});
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  const rowSelectHandler = (params) => {
    const { unique_id } = params.data;
    navigate(`/manufacture/order/${encodeURIComponent(unique_id)}`);
  };

  useEffect(() => {
    if (gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
    return () => {
      dispatch(manufactureOrderActions.resetAllManufactureOrders());
    };
  }, [dispatch, onFilterChanged]);

  return (
    <>
      {manufacturePermission === "NONE" && <Navigate to="/no-permission" />}
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3 }}>
        <Typography variant="h5">{t("manufacture.order.index")}</Typography>
      </Box>
      <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
      <AgGrid
        ref={gridRef}
        columnDefs={manufactureOrderColumnDef(t, false, tagOptions)}
        onGridReady={onGridReady}
        onRowDoubleClicked={rowSelectHandler}
        height={649}
      />
    </>
  );
};

export default ManufactureOrder;
