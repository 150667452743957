import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isLoading: {
    allUsers: false,
    user: false,
  },
  allUsers: [],
  user: {
    unique_id: "",
    title_name: "",
    first_name: "",
    last_name: "",
    nick_name: "",
    img_url: "",
    email: "",
    phone: "",
    organization: "",
    department: "",
    position: "",
    password: "",
    created_date: "",
    last_login_date: "",
    is_user_active: true,
    inactive_remarks: "",
    role_list: [],
    current_role: "",
  },
  error: null,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllUsers(state, action) {
      state.allUsers = action.payload;
      state.isLoading.allUsers = false;
    },
    appendedNewUser(state, action) {
      state.allUsers = [...state.allUsers, action.payload];
      state.isLoading.allUsers = false;
      state.isLoading.user = false;
    },
    loadedUser(state, action) {
      state.user = action.payload;
      state.isLoading.user = false;
    },
    resetUser(state) {
      state.user = initialState.user;
      state.isLoading.user = false;
    },
    updatedUser(state, action) {
      const updatedUser = action.payload;
      const updatedIndex = state.allUsers.findIndex(
        (user) => user.unique_id === updatedUser.unique_id
      );
      state.user = updatedUser;
      state.allUsers[updatedIndex] = updatedUser;
      state.isLoading.allUsers = false;
      state.isLoading.user = false;
    },
    disabledUser(state, action) {
      state.allUsers = state.allUsers.filter(
        (user) => user.unique_id !== action.payload.unique_id
      );
      state.isLoading.allUsers = false;
    },
  },
});

export const accountActions = accountSlice.actions;

export default accountSlice.reducer;
