import { useRef, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import AgGrid from "../../components/Table/AgGrid";
import ContactService from "../../services/Contact";
import CustomizedButton from "../../components/Custom/CustomizedButton";
import CustomizedStatus from "../../components/Custom/CustomizedStatus";
import CustomizedTab from "../../components/Custom/CustomizedTab";
import { contactColumnDefs } from "../../components/Table/ColumnDefs/Contact";
import { exportAsExcel } from "../../utils/exporter";
import { useAuth } from "../../hooks/use-auth";
import { contactExportFormatter } from "../../utils/contactPayloadFormatter";

const Contact = () => {
  const gridRef = useRef();
  const filterRef = useRef();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const tab = searchParams.get("type");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [contactsFilterOptions, setContactsFilterOptions] = useState(null);
  const { permissions } = useAuth();
  const contactPermission = permissions.contact;

  filterRef.current = filter;

  useEffect(() => {
    switch (tab) {
      case "vendor":
        setFilter({
          is_vendor: { filterType: "boolean", type: "equals", filter: "true" },
        });
        break;
      case "customer":
        setFilter({
          is_customer: {
            filterType: "boolean",
            type: "equals",
            filter: "true",
          },
        });
        break;
      default:
        setFilter({});
    }
    if (gridRef.current.api) {
      gridRef.current.api.onFilterChanged();
    }
  }, [gridRef, tab]);

  const formatFilterModel = (filterModel) => {
    const isActiveFilter = filterModel?.is_active;
    const activeFilterValue =
      isActiveFilter?.values[0] === "ใช้งาน" ? "true" : "false";
    const formattedFilterModel = {
      ...filterModel,
      ...filterRef.current,
      unique_id: {
        ...filterModel.unique_id,
        mode: "insensitive",
      },
      is_active:
        isActiveFilter && isActiveFilter?.values?.length === 1
          ? {
              filterType: "boolean",
              type: "equals",
              filter: activeFilterValue,
            }
          : undefined,
      tag_list: undefined,
      business_group_field: {
        ...filterModel.business_group_field,
        mode: "insensitive",
      },
      customer_quality: {
        ...filterModel.customer_quality,
        mode: "insensitive",
      },
    };
    return formattedFilterModel;
  };

  const exportHandler = async () => {
    try {
      const filterModel = formatFilterModel(
        gridRef.current.api.getFilterModel()
      );
      const allContacts = await ContactService.getAllContactsAgGrid({
        filterModel,
        startRow: 0,
        endRow: Math.pow(10, 9),
      });

      const formattedContact = contactExportFormatter(
        allContacts.results,
        true
      );

      exportAsExcel(formattedContact, "ผู้ติดต่อ", null, t, "contact");
    } catch (err) {
      console.log("ไม่สามารถนำออกผู้ติดต่อได้");
    }
    setIsLoading(false);
  };

  const tagListClickHandler = (tag) => {
    if (gridRef.current.api) {
      const instance = gridRef.current.api.getFilterInstance("tag_list");
      instance.setModel({ filterType: "text", filter: tag });
      gridRef.current.api.onFilterChanged();
    }
  };

  const datasource = {
    async getRows(params) {
      const request = params.request;
      const agGridOptions = {
        startRow: request.startRow,
        endRow: request.endRow,
        filterModel: formatFilterModel(request.filterModel),
        sortModel: request.sortModel,
      };
      try {
        const allContacts = await ContactService.getAllContactsAgGrid(
          agGridOptions,
          params
        );
        // setContactsFilterOptions(agGridOptions);
        params.successCallback(allContacts.results, allContacts.count);
      } catch (err) {
        console.log(err);
        params.failCallback();
      }
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const rowSelectHandler = (params) => {
    const { unique_id } = params.data;
    navigate(`/contact/${encodeURIComponent(unique_id)}`);
  };

  const onFirstDataRendered = () => {
    const instance = gridRef.current.api.getFilterInstance("is_active");
    instance?.setModel({ filterType: "set", values: ["ใช้งาน"] });
    gridRef.current.api.onFilterChanged();
  };

  const onAddContact = () => {
    navigate("/contact/add");
  };

  const tabs = [
    {
      label: "ทั้งหมด",
      path: `${pathname}`,
    },
    {
      label: t("contact.contact_type.customer"),
      path: `${pathname}?type=customer`,
    },
    {
      label: t("contact.contact_type.vendor"),
      path: `${pathname}?type=vendor`,
    },
  ];

  const currentTab = tab ? pathname + `?type=${tab}` : pathname;

  return (
    <>
      {contactPermission === "NONE" && <Navigate to="/no-permission" />}
      <CustomizedStatus bgcolor="#C8EDDC">
        <Typography
          variant="button"
          sx={{
            color: "#246527",
          }}
        >
          ใช้งาน
        </Typography>
      </CustomizedStatus>
      <Box
        sx={{
          mt: 3,
          display: { xs: "block", md: "flex" },
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">{t("contact.index")}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: { xs: 2, md: 0 },
          }}
        >
          {contactPermission !== "VIEW__ONLY" && (
            <Box mx={1}>
              <CustomizedButton
                title="นำเข้าผู้ติดต่อด้วยไฟล์ Excel"
                variant="outlined"
                onClick={() => navigate(`${pathname}/import`)}
                testId="contact-import-btn"
              />
            </Box>
          )}
          <Box mx={1}>
            <CustomizedButton
              title="นำออกผู้ติดต่อด้วยไฟล์ Excel"
              variant="outlined"
              onClick={exportHandler}
              testId="contact-export-btn"
              disabled={isLoading}
            />
          </Box>
          {contactPermission !== "VIEW__ONLY" && (
            <Box xl={1}>
              <CustomizedButton
                sx={{ mr: 2 }}
                title="สร้างผู้ติดต่อ"
                variant="contained"
                onClick={onAddContact}
                testId="contact-create-btn"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ maxWidth: "100%", overflow: "scroll", mt: 4 }}>
        <CustomizedTab tabs={tabs} currentTab={currentTab} scrollable table />
      </Box>
      <AgGrid
        ref={gridRef}
        columnDefs={contactColumnDefs(t)}
        height={649}
        onGridReady={onGridReady}
        onRowDoubleClicked={rowSelectHandler}
        onFirstDataRendered={onFirstDataRendered}
        testId={"contact-table"}
      />
    </>
  );
};

export default Contact;
