import { Box, Typography } from "@mui/material";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedAvatar from "../../../components/Custom/CustomizedAvatar";
// import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import AgGrid from "../../../components/Table/AgGrid";
import { useSnackbar } from "notistack";
import { getAllRoles } from "../../../features/User/Rbac/rbac-action";
import { useAuth } from "../../../hooks/use-auth";

const Rbac = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { permissions } = useAuth();

  const userPermission = permissions.user;
  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.rbac.index"),
    },
  ];

  const columnDefs = [
    {
      field: "name",
      headerName: t("user.rbac.index"),
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      field: "description",
      headerName: t("user.rbac.description"),
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      headerName: t("user.index"),
      filter: "agNumberColumnFilter",
      cellRenderer: (params) => {
        return <CustomizedAvatar avatars={params.data.user_list} />;
      },
      flex: 1,
    },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      dispatch(
        getAllRoles(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: request.filterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const onRowDoubleClicked = (e) => {
    navigate(`${pathname}/${e.data.unique_id}`);
  };

  return (
    <>
      {userPermission === "NONE" && <Navigate to="/no-permission" />}
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("user.rbac.role")}</Typography>
        {userPermission !== "VIEW__ONLY" && (
          <CustomizedButton
            title={t("user.rbac.addNewRole")}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
        )}
      </Box>
      <Box sx={{ mt: "2rem" }}>
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          height={649}
          onGridReady={onGridReady}
          // rowData={[]}
          onRowDoubleClicked={onRowDoubleClicked}
          rowSelection={"multiple"}
          enableRangeSelection={true}
        />
      </Box>
    </>
  );
};

export default Rbac;
